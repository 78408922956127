import React from 'react';
import Assets from '../../lib/Assets';
import MenuIcon from './MenuIcon';
import MenuLinks from './MenuLinks';
import Utils from '../../lib/Utils';

const Menu = () => {
    return (
        <>
        <div className='home-nav shadow animate__animated animate__fadeIn width-100 centered bkg-white'>
            <div>
                <img onClick={() => {
                    Utils.goTo({url: `/home`})
                }} src={Assets.TopuphomeLogoInternals} id='the-logo' alt='Topuphome' title='Topuphome' className='pointer' />
            </div>
            <div className='nav-menu'>
                <div className='links-mvnvbv'>
                    <a title='Home' href='/home'>Home</a>
                    <a title='Logout' href='/logout'>Logout</a>
                </div>
                <div className='icon-mvnvbv'>
                    <MenuIcon />
                </div>
            </div>
        </div>
        <MenuLinks />
        </>
    )
}

export default Menu;