import React, { useEffect, useRef, useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import '../../css/ProgressBarStyles.css';
import { Box, CircularProgress, Dialog } from '@mui/material';

/**
 * Show a progress loader. It can be customized with 'mainClass' optional class name, which gives access 
 * some functions to get hold of the loader and show/hide it as needed.
 * The 'doingWhatClass' gives access to the 'doing what' text to a specific functions which uses the loader
 * @param {object} props 
 * @returns React Component
 */
const  ProgressBar = () => {
    console.log('{ProgressBar}');
    const [configs, setProgressBarConfigs] = useState({
        show: false,
        msg: 'Processing...'
    });
    const colors = {
        red: '#b50015',
        green: '#329a33',
        black: '#000000'
    };
    const [colorSwitcher, setColorSwitcher] = useState({
        color: colors.red
    });

    const colorSwitcherRef = useRef(0);
    const colorSwitcherIndexRef = useRef(1);

    /**
     * Switch the colors and apply to the progress bar
     * @returns {number} The setInterval Id
     */
    const startColorSwitcher = () => {
        const colorMap = {1: 'red', 2: 'green', 3: 'black'};
        return setInterval(() => {
            setColorSwitcher({
                color: colors[colorMap[colorSwitcherIndexRef.current]]
            });
            colorSwitcherIndexRef.current++;
            if(colorSwitcherIndexRef.current === 4) {
                colorSwitcherIndexRef.current = 1;
            }
        },1700);
    }
    StateUpdators.setProgressBarConfigs = setProgressBarConfigs;
    useEffect(() => {
        if(configs.show) {
            colorSwitcherRef.current = startColorSwitcher();
            return () => clearInterval(colorSwitcherRef.current);
        } else {
            return undefined;
        }
    });
    return(
        <>
        <Dialog open={configs.show}>
        {configs.show && 
            <div id='progressBar' className='animate__animated animate__fadeIn rounded shadow'>
                <Box sx={{width: '100%'}}>
                    <CircularProgress size={60} />
                </Box>
                <div id='doingWhat'>
                    <p><b>{configs.msg}</b></p>
                </div>
            </div>
        }
        </Dialog>
        </>
    )
}

export default ProgressBar;